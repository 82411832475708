<template>
  <div class="about-emp-style">
    <h2 align="center" class="h-two">工作环境</h2>
    <div class="environment">
      <p><span>弹性灵活</span>的工作时间，你的时间你做主</p>
      <div class="swiper env-swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="item in someList" :key="item.id">
            <img :src="item.url" alt="" />
          </div>
        </div>
        <!-- <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div> -->
      </div>
    </div>

    <h2 align="center" class="h-two">团队建设</h2>
    <a-row class="construction" type="flex">
      <a-col class="col-l" :span="12">
        <div></div>
        <p>工作之余，也会定期安排<span>丰富多彩的活动</span></p>
        <ul>
          <li v-for="item in list" :key="item.id">
            <h3>{{ item.h }}</h3>
            <img src="../../assets/image/32628.png" alt="" />
            <p>{{ item.p }}</p>
          </li>
        </ul>
      </a-col>
      <a-col class="col-r" :span="12">
        <!-- <div></div> -->
        <ul>
          <!-- <li class="wrap">
            <div class="left common"></div>
            <div class="right common"></div>
          </li> -->
          <li class="wrap" v-for="item in empImgList" :key="item.id"
            :style="{ '--bgurl': 'url(' + item.empImg + ') 50% 50% no-repeat' }">
            <div class="left common" :style="{
              '--bgurl': 'url(' + item.empImg + ') 50% 50% no-repeat',
            }"></div>
            <div class="right common" :style="{
              '--bgurl': 'url(' + item.empImg + ') 50% 50% no-repeat',
            }"></div>
            <!-- <div class="show-bottom-box"></div> -->
          </li>
        </ul>
      </a-col>
    </a-row>

    <!-- 心声 -->
    <!-- <h2 align="center" class="h-two">员工心声</h2>
    <div class="voice">
      <div class="swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="item in voiceList" :key="item.id">
            <p>
              <span class="box"></span>
              <span class="sp"></span>
              <span class="name">{{item.name}}</span>
              {{item.emp}}
            </p>
            <span class="text">{{item.text}}</span>
          </div>
        </div>
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>
    </div> -->
  </div>
</template>

<script>
import Swiper from "../../../node_modules/swiper/swiper-bundle.js";
export default {
  components: {},
  data() {
    return {
      someList: [
        { id: 1, url: require("../../assets/image/158.png") },
        { id: 2, url: require("../../assets/image/157.png") },
        { id: 3, url: require("../../assets/image/177.png") },
      ],
      list: [
        { id: 1, h: "团队活动", p: "在工作之余也有丰富的活动" },
        { id: 2, h: "周年庆", p: "每次周年活动都给大家充满了惊喜" },
        { id: 3, h: "全体团建", p: "自己定制的全球游，还在等什么呢" },
      ],

      voiceList: [
        {
          id: 1,
          name: "陈小春",
          emp: "UI设计部",
          text: "“思如泉涌，成竹在图 在达译做设计的体悟便是如此。自由的环境激发出200%的灵感，但我们不仅要做有创造力的设计，还要做有深度的设计。设计师是为激发灵感和创意而生，keep thinking，才有更多点子，更多可能性。”",
        },
        {
          id: 2,
          name: "陈小喜",
          emp: "UI设计部",
          text: "“思如泉涌，成竹在图 在达译做设计的体悟便是如此。自由的环境激发出200%的灵感，但我们不仅要做有创造力的设计，还要做有深度的设计。设计师是为激发灵感和创意而生，keep thinking，才有更多点子，更多可能性。”",
        },
        {
          id: 3,
          name: "陈小喜",
          emp: "UI设计部",
          text: "“思如泉涌，成竹在图 在达译做设计的体悟便是如此。自由的环境激发出200%的灵感，但我们不仅要做有创造力的设计，还要做有深度的设计。设计师是为激发灵感和创意而生，keep thinking，才有更多点子，更多可能性。”",
        },
        {
          id: 4,
          name: "陈小喜",
          emp: "UI设计部",
          text: "“思如泉涌，成竹在图 在达译做设计的体悟便是如此。自由的环境激发出200%的灵感，但我们不仅要做有创造力的设计，还要做有深度的设计。设计师是为激发灵感和创意而生，keep thinking，才有更多点子，更多可能性。”",
        },
        {
          id: 5,
          name: "陈小喜",
          emp: "UI设计部",
          text: "“思如泉涌，成竹在图 在达译做设计的体悟便是如此。自由的环境激发出200%的灵感，但我们不仅要做有创造力的设计，还要做有深度的设计。设计师是为激发灵感和创意而生，keep thinking，才有更多点子，更多可能性。”",
        },
      ],

      empImgList: [
        {
          id: 1,
          empText: "全公司两天一夜户外露营团建活动",
          empDate: "2020.11.11",
          empImg: require("../../assets/image/1001.jpg"),
        },
        {
          id: 2,
          empText: "全公司两天一夜户外露营团建活动",
          empDate: "2020.11.11",
          empImg: require("../../assets/image/1002.jpg"),
        },
        {
          id: 3,
          empText: "全公司两天一夜户外露营团建活动",
          empDate: "2020.11.11",
          empImg: require("../../assets/image/1003.jpg"),
        },
        {
          id: 4,
          empText: "全公司两天一夜户外露营团建活动",
          empDate: "2020.11.11",
          empImg: require("../../assets/image/1004.jpg"),
        },
        {
          id: 5,
          empText: "全公司两天一夜户外露营团建活动",
          empDate: "2020.11.11",
          empImg: require("../../assets/image/1005.jpg"),
        },
        {
          id: 6,
          empText: "全公司两天一夜户外露营团建活动",
          empDate: "2020.11.11",
          empImg: require("../../assets/image/1006.jpg"),
        },
        {
          id: 7,
          empText: "全公司两天一夜户外露营团建活动",
          empDate: "2020.11.11",
          empImg: require("../../assets/image/1007.jpg"),
        },
        {
          id: 8,
          empText: "全公司两天一夜户外露营团建活动",
          empDate: "2020.11.11",
          empImg: require("../../assets/image/1008.jpg"),
        },
        {
          id: 9,
          empText: "全公司两天一夜户外露营团建活动",
          empDate: "2020.11.11",
          empImg: require("../../assets/image/1009.jpg"),
        },
        {
          id: 10,
          empText: "全公司两天一夜户外露营团建活动",
          empDate: "2020.11.11",
          empImg: require("../../assets/image/1010.jpg"),
        },
      ],

      // empSty: {
      //   linear: ''
      // }
    };
  },
  mounted() {
    var mySwiper = new Swiper(".swiper", {
      direction: "horizontal", // 垂直切换选项
      loop: true,
      breakpoints: {
        320: {
          //当屏幕宽度大于等于320
          slidesPerView: 2,
          spaceBetween: 10,
        },
        768: {
          //当屏幕宽度大于等于768
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1280: {
          //当屏幕宽度大于等于1280
          slidesPerView: 4,
          spaceBetween: 30,
        },
      },
      autoplay: {
        delay: 3000,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      disableOnInteraction: false,
    });
  },
  methods: {
    showImg(item) {
      console.log(item);
    },
  },
};
</script>

<style lang="less">
.about-emp-style {
  .h-two {
    margin-top: 120px;
    color: #0e0c1b;
    font-size: 40px;
    font-family: Source Han Sans CN;
    font-weight: bold;
  }

  .environment {
    width: 1440px;
    margin: 0 auto;
    text-align: center;

    p {
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 400;

      span {
        color: #ff6e29;
      }
    }

    .env-swiper {
      border-radius: 15px;

      .swiper-wrapper {
        .swiper-slide {
          img {
            width: 300px;
            height: 200px;
          }
        }
      }
    }
  }

  .construction {
    width: 1440px;
    margin: 0 auto;

    // h2 {
    //   font-size: 40px;
    //   font-family: Source Han Sans CN;
    //   font-weight: bold;
    //   margin-bottom: 0px;
    // }
    .col-l {
      div {
        width: 131px;
        height: 18px;
        background: linear-gradient(to right, #ff812e, #ffffff);
      }

      p {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        margin-top: 26px;

        span {
          color: #ff6e29;
        }
      }

      ul {
        li {
          margin-top: 93px;

          h3 {
            font-size: 24px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            display: inline;
          }

          img {
            margin-left: 20px;
            width: 58px;
            height: 14px;
          }

          p {
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            margin-top: 10px;
          }
        }
      }
    }

    .col-r {

      // background-color: turquoise;
      ul {
        width: 100%;
        height: 100%;

        .wrap {
          height: 110px;
          width: 190px;
          position: relative;
          background: var(--bgurl);
          background-size: auto 220px;
          transition: 0.5s all ease;
          box-sizing: content-box;

          .common {
            position: absolute;
            height: 100%;
            width: 100%;
            overflow: hidden;
            left: 0;

            &::before {
              content: "";
              position: absolute;
              background: var(--bgurl);
              background-size: auto 220px;
              width: 190px;
              height: 220px;
            }
          }

          .left {
            transform: rotate(60deg);

            &::before {
              transform: rotate(-60deg) translate(48px, -28px);
            }
          }

          .right {
            transform: rotate(-60deg);

            &::before {
              transform: rotate(60deg) translate(48px, 28px);
              bottom: 0;
            }
          }

          &:hover {
            z-index: 999;
            transform: scale(1.4);
            box-shadow: 0px 10px 20px #cccccc;

            // border-left: 10px solid red;
            // border-right: 10px solid red;
            .left,
            .right {
              // border-left: 10px solid red;
              // border-right: 10px solid red;
            }
          }

          &:nth-child(1) {
            position: absolute;
            top: 40px;
          }

          &:nth-child(2) {
            position: absolute;
            right: 105px;
            top: 40px;
          }

          &:nth-child(3) {
            position: absolute;
            left: 105px;
            top: 220px;
          }

          &:nth-child(4) {
            position: absolute;
            left: 315px;
            top: 220px;
          }

          &:nth-child(5) {
            position: absolute;
            left: 525px;
            top: 220px;
          }

          &:nth-child(6) {
            position: absolute;
            left: 0px;
            top: 400px;
          }

          &:nth-child(7) {
            position: absolute;
            left: 210px;
            top: 400px;
          }

          &:nth-child(8) {
            position: absolute;
            left: 420px;
            top: 400px;
          }

          &:nth-child(9) {
            position: absolute;
            left: 310px;
            top: 580px;
          }

          &:nth-child(10) {
            position: absolute;
            left: 515px;
            top: 580px;
          }
        }

        // li {
        //   position: relative;
        //   width: 100px;
        //   height: 173.2px;
        //   background-color: #ff6e29;
        //   transform: rotate(90deg);
        //   // float: left;
        //   &::before {
        //     content: "";
        //     display: block;
        //     position: absolute;
        //     width: 0;
        //     height: 0;
        //     right: 99px;
        //     border-width: 86.7px 50px;
        //     border-style: solid;
        //     border-color: transparent #ff6e29 transparent transparent;
        //   }
        //   &::after {
        //     content: "";
        //     display: block;
        //     position: absolute;
        //     width: 0;
        //     height: 0;
        //     left: 99px;
        //     border-width: 86.6px 50px;
        //     border-style: solid;
        //     border-color: transparent transparent transparent #ff6e29;
        //     top: 0;
        //   }
        //   &:nth-child(2){
        //     position:absolute;
        //     right: 40px;
        //     top: 0px;
        //   }
        //   &:nth-child(3){
        //     position: absolute;
        //     left: 100px;
        //     top: 170px;
        //   }
        //   &:nth-child(4){
        //     position: absolute;
        //     left: 290px;
        //     top: 170px;
        //   }
        //   &:nth-child(5){
        //     position: absolute;
        //     left: 480px;
        //     top: 170px;
        //   }
        //   &:nth-child(6){
        //     position: absolute;
        //     left: 0px;
        //     top: 330px;
        //   }
        //   &:nth-child(7){
        //     position: absolute;
        //     left: 195px;
        //     top: 330px;
        //   }
        //   &:nth-child(8){
        //     position: absolute;
        //     left: 385px;
        //     top: 330px;
        //   }
        //   &:nth-child(9){
        //     position: absolute;
        //     left: 290px;
        //     top: 490px;
        //   }
        //   &:nth-child(10){
        //     position: absolute;
        //     left: 480px;
        //     top: 490px;
        //   }
        // }
      }
    }
  }

  .voice {
    width: 1440px;
    margin: 0 auto;
    margin-bottom: 60px;

    .swiper {
      border-radius: 20px;

      .swiper-wrapper {
        padding: 20px;

        .swiper-slide {
          padding: 20px;
          box-shadow: 10px 10px 30px rgba(23, 35, 55, 0.1);
          border-top-left-radius: 40px;
          border-bottom-right-radius: 40px;

          p {
            .box {
              display: inline-block;
              width: 92px;
              height: 8px;
              margin-right: 10px;
              background-color: #ff854b;
            }

            font-size: 24px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #0e0c1b;

            .sp {
              width: 92px;
              height: 8px;
              background-color: red;
            }

            .name {
              color: #e95806;
            }
          }

          .text {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
          }
        }
      }
    }
  }
}

@media all and (max-width: 700px) {
  .about-emp-style {
    .h-two {
      margin-top: 120px;
      color: #0e0c1b;
      font-size: 80px;
      font-family: Source Han Sans CN;
      font-weight: bold;
    }

    .environment {
      width: 1440px;
      margin: 0 auto;
      text-align: center;

      p {
        font-size: 40px;
        font-family: Source Han Sans CN;
        font-weight: 800;

        span {
          color: #ff6e29;
        }
      }

      .env-swiper {
        border-radius: 15px;

        .swiper-wrapper {
          .swiper-slide {
            img {
              width: 600px;
              height: 400px;
            }
          }
        }
      }
    }

    .construction {
      width: 1440px;
      margin: 0 auto;

      // h2 {
      //   font-size: 40px;
      //   font-family: Source Han Sans CN;
      //   font-weight: bold;
      //   margin-bottom: 0px;
      // }
      .col-l {
        div {
          width: 131px;
          height: 18px;
          background: linear-gradient(to right, #ff812e, #ffffff);
        }

        p {
          font-size: 40px;
          font-family: Source Han Sans CN;
          font-weight: 800;
          margin-top: 26px;

          span {
            color: #ff6e29;
          }
        }

        ul {
          li {
            margin-top: 93px;

            h3 {
              font-size: 48px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              display: inline;
            }

            img {
              margin-left: 20px;
              width: 106px;
              height: 28px;
            }

            p {
              font-size: 36px;
              font-family: Source Han Sans CN;
              font-weight: 800;
              margin-top: 10px;
            }
          }
        }
      }

      .col-r {

        // background-color: turquoise;
        ul {
          width: 100%;
          height: 100%;

          .wrap {
            height: 110px;
            width: 190px;
            position: relative;
            background: var(--bgurl);
            background-size: auto 220px;
            transition: 0.5s all ease;
            box-sizing: content-box;

            .common {
              position: absolute;
              height: 100%;
              width: 100%;
              overflow: hidden;
              left: 0;

              &::before {
                content: "";
                position: absolute;
                background: var(--bgurl);
                background-size: auto 220px;
                width: 190px;
                height: 220px;
              }
            }

            .left {
              transform: rotate(60deg);

              &::before {
                transform: rotate(-60deg) translate(48px, -28px);
              }
            }

            .right {
              transform: rotate(-60deg);

              &::before {
                transform: rotate(60deg) translate(48px, 28px);
                bottom: 0;
              }
            }

            &:hover {
              z-index: 999;
              transform: scale(1.4);
              box-shadow: 0px 10px 20px #cccccc;

              // border-left: 10px solid red;
              // border-right: 10px solid red;
              .left,
              .right {
                // border-left: 10px solid red;
                // border-right: 10px solid red;
              }
            }

            &:nth-child(1) {
              position: absolute;
              top: 40px;
            }

            &:nth-child(2) {
              position: absolute;
              right: 105px;
              top: 40px;
            }

            &:nth-child(3) {
              position: absolute;
              left: 105px;
              top: 220px;
            }

            &:nth-child(4) {
              position: absolute;
              left: 315px;
              top: 220px;
            }

            &:nth-child(5) {
              position: absolute;
              left: 525px;
              top: 220px;
            }

            &:nth-child(6) {
              position: absolute;
              left: 0px;
              top: 400px;
            }

            &:nth-child(7) {
              position: absolute;
              left: 210px;
              top: 400px;
            }

            &:nth-child(8) {
              position: absolute;
              left: 420px;
              top: 400px;
            }

            &:nth-child(9) {
              position: absolute;
              left: 310px;
              top: 580px;
            }

            &:nth-child(10) {
              position: absolute;
              left: 515px;
              top: 580px;
            }
          }
        }
      }
    }
  }
}</style>
